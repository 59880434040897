<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
  >
    <el-table-column
      prop="date"
      align="center"
      min-width="100"
      label="日期"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="keyword"
      align="center"
      label="关键词"
      min-width="100"
    >
    </el-table-column>
    <el-table-column
      prop="related_word_txt"
      align="center"
      label="系统关联关键词"
      min-width="100"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="related_word_txt"
      align="center"
      label="绑定关键词"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <div>{{ word(scope.row) }}</div>
      </template>
    </el-table-column>
    <el-table-column
      prop="number"
      align="center"
      label="搜索次数"
      min-width="100"
    >
    </el-table-column>
    <el-table-column
      prop="purchase_deduction_amount"
      align="center"
      label="操作"
    >
      <template slot-scope="scope">
        <el-button type="text" @click="onHandleEdit(scope.row)">
          关联关键词
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "TableList",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    // 绑定关键词
    word(row) {
      let name = "";
      if (
        row.member_search_bind &&
        row.member_search_bind.related_words.length > 0
      ) {
        name = row.member_search_bind.related_words.join(",");
      }
      return name;
    },
    /**
     * 关联关键词
     */
    onHandleEdit(row) {
      console.log("编辑", row);
      this.$emit("on-handle-zone", "initData", row);
    },
  },
};
</script>
<style lang="scss" scoped></style>
